<template>
  <div>
    <div style="margin-top: 80px">
      <!-- Begin Breadcrumb Area -->
      <div class="breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-content">
            <h2>About Us</h2>
            <ul>
              <li><a href="/">Home</a></li>
              <li class="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
      <!--Breadcrumb Area End Here -->
      <!-- Begin  About Us Area -->
      <div class="about-us-area">
        <div class="container-fluid">
          <div class="row">
            
            <div class="col-lg-6 col-md-7 d-flex align-items-center">
              <div class="overview-content">
                <h2>About Us</h2>
                <p class="short_desc">
                  We are an ambitious company based in Kerala, dedicated to us here in a groundbreaking transformation in the automobile industry. Inspired by fourotech's creativity, Ibidz is a dynamic startup driven by unwavering passion. Our core mission revolves around re-shaping how suppliers navigate the used automobile sector. We are firmly committed to creating mutually beneficial scenarios, providing customers with unparalleled options, and enhancing the selling process for suppliers. Through cutting-edge technologies and an unwavering pursuit of excellence, we collaborate to revolutionize the used automobile market.
                </p>
                
                <div class="uren-about-us_btn-area">
                  <a class="about-us_btn" href="FeedBackForm">Contact Us</a>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-5">
              <div class="overview-img text-center img-hover_effect">
                
                  <img
                    class="img-full"
                    src="/assets/images/1.jpg"
                    alt="About Us Image"
                  />
                
              </div>
            </div>
          </div>


          <div style="    margin: 30px 0px;">
            <div class="row" >
              <div class="col-md-6">
               <div>
                  <img src="https://www.arb2blogistics.com/image/about/mission-vision.jpg" style="width: 100%;margin-top: 35px;">
               </div>
              </div>
              <div class="col-md-6">
                <div class="visionmission">
                  <h3>Our Vision</h3>
                  <P>Our vision is to serve as a catalyst for a fundamental shift in the automotive industry. We aspire to forge a future where innovation and technology converge to redefine the automotive market. We envision a market that empowers both sellers and consumers, setting new benchmarks for excellence and convenience by championing transparency, efficiency, and accessibility.</P>
                </div>
                <div class="visionmission">
                  <h3>Our Mission</h3>
                  <p>Our mission is to transform the global landscape of pre-owned VEHICLE transactions through remarkable change and innovation in the automotive sector. With resolute dedication and groundbreaking ideas, we aim to simplify, streamline, and enrich the vendor and customer experience.</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-6">
                <div class="about-bot">
                  <h3>ABOUT IBIDZ ONLINE BIDDING APP</h3>
                  <p>The introduction of our online bidding platform stands as one of our most innovative endeavors. This platform is designed to foster transparency, efficiency, and simplicity in the selling process. With online bidding, the auction never ceases, ensuring that merchants consistently attract enthusiastic buyers bidding on their vehicles.</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="about-bot">
                  <h3>JOIN WITH US ON THIS JOURNEY</h3>
                  <p>Ibidz extends a warm invitation for you to embark on this transformative journey with us. We urge you to be a part of it, whether you're a seller seeking to streamline, your selling process or a buyING search of the perfect used car. We offer A top-notch service to all our clients. Choose Ibidz, powered by fourotech, for a smarter and more creative approach to your used automobile buying and selling decisions.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  About Us Area End Here -->

      <!-- Begin Project Countdown Area -->
      <!-- <div class="project-count-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-ios-briefcase-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">2169</h2>
                  <span>Project Done</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-ios-wineglass-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">869</h2>
                  <span>Awards Winned</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-ios-lightbulb-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">689</h2>
                  <span>Hours Worked</span>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="single-count text-center">
                <div class="count-icon">
                  <span class="ion-happy-outline"></span>
                </div>
                <div class="count-title">
                  <h2 class="count">2169</h2>
                  <span>Happy Customer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- Project Countdown Area End Here -->
    </div>
  </div>
</template>
<script>
export default {
  created(){
    this.scrollToTop();
  },
  methods:{
      scrollToTop() {
    window.scrollTo(0,0);
  }
  }
}
</script>